import axios from 'axios'
import {
  BASEURL
} from './index'
const prefix = '/dseWeb'
import encryptMd5 from 'encrypt-md5'
import store from '@/store/index'

const service = axios.create({
  baseURL: BASEURL,
  timeout: 5000
})

service.interceptors.request.use(config => {
  const token = store.getters.getToken
  if (token) {
    config.headers = {
      Authorization: token
    }
  }
  if (
    (config.headers['Content-Type'] && config.headers['Content-Type'].includes('application/json')) ||
    (config.data && typeof config.data === 'object' && !(config.data instanceof FormData))
  ) {
    config.headers['dseSignMD5'] = encryptMd5.md5(JSON.stringify(config.data))
  }
  return config
})
service.interceptors.response.use(config => {
    return config
  },
  error => {
    if (error.response.config.responseType == 'blob') {
      let blob = new Blob([error.response.data])
      let reader = new FileReader()
      reader.readAsText(blob, 'utf-8')
      reader.onload = function () {
        try {
          let data = JSON.parse(reader.result)
          if (data.code !== 200) {
            store.commit('setError', {
              status: true,
              title: '',
              message: data.message,
              type: 'error'
            })
          }
        } catch (e) {
          console.log(e)
        }
      }
    }
  })

export function reportPreview(params) {
  return service({
    url: `${prefix}/v1/op/devOpsReport/preview`,
    method: 'get',
    params: params,
    responseType: 'arraybuffer'
  })
}

export function exportReportRequest(params) {
  return service({
    url: `${prefix}/v1/op/devOpsReport/exportReport`,
    method: 'get',
    params: params,
    responseType: 'blob'
  })
}

export function exportWorkLogRequest(data) {
  return service({
    url: `${prefix}/v1/op/exportWorkLogList`,
    method: 'post',
    data: data,
    responseType: 'blob'
  })
}
export function exportRtuAttrHis(data) {
  return service({
    url: `${prefix}/v1/exportRtuAttrHis`,
    method: 'post',
    data: data,
    responseType: 'blob'
  })
}